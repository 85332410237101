import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/email-verify",
      name: "email-verify",
      component: () => import("@/views/EmailVerifyView.vue"),
    },
    {
      path: "/verify-delete",
      name: "verify-delete",
      component: () => import("@/views/DeleteAccountVerificationView.vue"),
    },
    //http://localhost:8080/verify-url=http%3A%2F%2Flocalhost%3A8000%2Fapi%2Fauth%2Femail%2Fverify%2F4%2F3174069ac4f3a9d8f3753ebb23cea9ccf7b0e0b7%3Fexpires%3D1674895160%26signature%3D0135533c27495128ffe15768b9d2d60b2c1d97be537cdece117c113954cee3dc
    {
      path: "/auth/:provider/callback",
      name: "social-login-callback",
      component: () => import("@/views/SocialLoginView.vue"),
    },
    {
      path: "/registration-callback",
      name: "registration-callback",
      component: () => import("@/views/RegistrationCallbackView.vue"),
    },
    {
      path: "/subscription-callback",
      name: "subscription-callback",
      component: () => import("@/views/SubscriptionCallbackView.vue"),
    },
    {
      path: "/",
      name: "channels",
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/checkout",
      name: "checkout",
      component: () => import("@/views/CheckoutView.vue"),
      meta: {
        onlyAuth: true,
        pageTitle: "Checkout",
        breadcrumb: [
          {
            text: "Checkout",
            active: true,
          },
        ],
      },
    },

    {
      path: "/callback",
      name: "callback",
      component: () => import("@/views/CallbackView.vue"),
      meta: {
        pageTitle: "Callback",
      },
    },
    {
      path: "/prices",
      name: "prices",
      component: () => import("@/views/PricingView.vue"),
      meta: {
        pageTitle: "Prices",
        breadcrumb: [
          {
            text: "Prices",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/admin/UsersView.vue"),
      meta: {
        pageTitle: "Users",
        breadcrumb: [
          {
            text: "Users",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },

    {
      path: "/users/:id",
      name: "user",
      component: () => import("@/views/admin/UserView.vue"),
      meta: {
        pageTitle: "Users",
        breadcrumb: [
          {
            text: "Users",
            active: false,
            to: "/users",
          },
          {
            text: "User",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/countries",
      name: "countries",
      component: () => import("@/views/admin/ContriesView.vue"),
      meta: {
        pageTitle: "Country list",
        breadcrumb: [
          {
            text: "Country list",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/ips",
      name: "ips",
      component: () => import("@/views/admin/IPsView.vue"),
      meta: {
        pageTitle: "IPs",
        breadcrumb: [
          {
            text: "IPs",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/ips/:ip",
      name: "ip",
      component: () => import("@/views/admin/IPView.vue"),
      meta: {
        pageTitle: "IPs",
        breadcrumb: [
          {
            text: "IPs",
            active: false,
            to: "/ips",
          },
          {
            text: "IP",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/payments",
      name: "payments",
      component: () => import("@/views/admin/PaymentListView.vue"),
      meta: {
        pageTitle: "Payment list",
        breadcrumb: [
          {
            text: "Payment list",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/payments/:id",
      name: "payment",
      component: () => import("@/views/admin/PaymentView.vue"),
      meta: {
        pageTitle: "Payment list",
        breadcrumb: [
          {
            text: "Payment list",
            active: false,
            to: "/payments",
          },
          {
            text: "Payment",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/user/UserProfile.vue"),
      meta: {
        onlyAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },

    {
      path: "/profile/subscription/:subscription_id",
      name: "profile-subscription",
      component: () => import("@/views/admin/SubscriptionView.vue"),
      meta: {
        pageTitle: "Subscriptions",
        breadcrumb: [
          {
            text: "Profile",
            active: false,
            to: "/profile",
          },
          {
            text: "Subscription",
            active: true,
          },
        ],
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/auth/ForgetPasswordView.vue"),
      meta: {
        layout: "full",
        onlyGuest: true,
      },
    },
    {
      path: "/registration",
      name: "registration",
      component: () => import("@/views/auth/RegistrationView.vue"),
      meta: {
        layout: "full",
        onlyGuest: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth/LoginView.vue"),
      meta: {
        layout: "full",
        onlyGuest: true,
      },
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: () => import("@/views/auth/ResetPasswordView.vue"),
      meta: {
        layout: "full",
        onlyGuest: true,
      },
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("@/views/admin/OrdersView.vue"),
      meta: {
        pageTitle: "Orders",
        breadcrumb: [
          {
            text: "Orders",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: () => import("@/views/contract/TermsView"),
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("@/views/contract/PrivacyView"),
    },
    {
      path: "/faq-list",
      name: "faq-list",
      component: () => import("@/views/contract/FaqListView"),
      meta: {
        breadcrumb: [
          {
            text: "FAQ list",
            active: true,
          },
        ],
      },
    },
    {
      path: "/faq-list/:id",
      name: "faq",
      component: () => import("@/views/contract/FaqView"),
      meta: {
        breadcrumb: [
          {
            text: "FAQ list",
            active: false,
            to: "/faq-list",
          },
          {
            text: "FAQ",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404View.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/ping",
      name: "ping",
      component: () => import("@/views/PongView.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/channel-list",
      name: "channel-list",
      component: () => import("@/views/admin/ChannelListView.vue"),
      meta: {
        pageTitle: "Channel list",
        breadcrumb: [
          {
            text: "Channel list",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/subscription-list",
      name: "subscription-list",
      component: () => import("@/views/admin/SubscriptionListView.vue"),
      meta: {
        pageTitle: "Subscription list",
        breadcrumb: [
          {
            text: "Subscription list",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/subscription-list/:subscription_id",
      name: "subscription",
      component: () => import("@/views/admin/SubscriptionView.vue"),
      meta: {
        pageTitle: "Subscription list",
        breadcrumb: [
          {
            text: "Subscription list",
            active: false,
            to: "/subscription-list",
          },
          {
            text: "Subscription",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/system-config",
      name: "system-config",
      component: () => import("@/views/admin/SysConfigList.vue"),
      meta: {
        pageTitle: "System config",
        breadcrumb: [
          {
            text: "System config",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/resend-verification-email",
      name: "resend-verification-email",
      component: () => import("@/views/auth/ResendVerificationEmailView.vue"),
      meta: {
        layout: "full",
        onlyGuest: true,
      },
    },
    {
      path: "/email-templates/:id",
      name: "email-template",
      component: () => import("@/views/admin/EmailTemplate.vue"),
      meta: {
        pageTitle: "Email templates",
        breadcrumb: [
          {
            text: "Email templates",
            active: false,
            to: "/email-templates",
          },
          {
            text: "Email template",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
    {
      path: "/email-templates",
      name: "email-templates",
      component: () => import("@/views/admin/EmailTemplateList.vue"),
      meta: {
        pageTitle: "Email templates",
        breadcrumb: [
          {
            text: "Email templates",
            active: true,
          },
        ],
        onlyAdmins: true,
      },
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, _, next) => {
  const authUser = store.getters["auth/authUser"];
  const isAdmin = store.getters["auth/isAdmin"];
  if (to?.meta?.onlyGuest && authUser && !isAdmin) {
    return next({ name: "channels" });
  }

  if (to?.meta?.onlyAuth && !authUser) {
    return next({ name: "channels" });
  }

  if (to?.meta?.onlyAdmins && !isAdmin) {
    return next({ name: "channels" });
  }

  return next();
});

export default router;
